import React from 'react';

function App() {
  return (
    <div className="min-h-screen flex justify-center items-center bg-[#E7E9E7]">
      <div className="max-w-full w-[1080px] px-6 py-8 flex flex-col gap-8">
        <div className="flex flex-col gap-6">
          <Divider />
          <div className="flex flex-col gap-3 items-start">
            <img src="/logo.svg" className="w-40 object-cover" alt="logo" />
            <img src="/name.svg" className="w-16 object-cover" alt="logo" />
          </div>
          <div className="flex">
            <div className="self-stretch text-[#16151D] text-base font-writer leading-relaxed tracking-wide">
              Fronis is a studio specializing in app/web service development.
            </div>
          </div>
          <Divider />
        </div>
        <div className="text-[#16151D] text-sm font-normal leading-snug tracking-wide">
          Phronesis (φρόνησις) is a concept of knowledge emphasized by Aristotle, denoting practical
          wisdom or prudence. It transcends theoretical knowledge and intellectual understanding,
          focusing on the capacity to make sound, morally grounded decisions in real-life
          situations. Phronesis entails a profound understanding of human nature, ethics, and the
          specific context in which individuals operate.
          <br />
          <br />
          Fronis is an app/web development company that embraces the concept of phronesis. We are
          dedicated to crafting solutions in the right way, considering both the needs of
          individuals and the broader societal requirements for services.
        </div>
        <img alt="brand" className="relative" src="/brand.png" />
      </div>
    </div>
  );
}

export default App;

const Divider = () => (
  <div className="w-full h-[0px] flex">
    <div className="w-full h-[0px] border-2 border-[#16151D]"></div>
  </div>
);
